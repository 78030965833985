import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastNotification } from 'carbon-components-react';
import { td } from '../utils/i18n';
import './EngagementDetails.css';
import banner from '../assets/collin.jpg';

export default function EngagementDetails({ engagementList }) {
  const { t, i18n } = useTranslation(['translation']);
  const capitalize = (word) =>
    word ? word.charAt(0).toUpperCase() + word.slice(1) : '';

  return (
    <div className="engagementDetailsWrapper">
      {engagementList && Array.isArray(engagementList) && engagementList.length > 0 && engagementList[0].orderErrors ? (
        <div className="bx--row">
          <ToastNotification 
            data-testid='order-warning'
            id='order-warning'
            kind='warning' 
            lowContrast={true}
            hideCloseButton={true}
            title='Warning - Some engagements did not provision successfully. Please try again' 
            subtitle={engagementList[0].orderErrors.join('. ')} 
            caption=''
          />
        </div>
      ) : ''}

      {engagementList && Array.isArray(engagementList)
        ? engagementList.map((engagement, index) => (
            <div data-testid={`engagementDetails-${index}`} key={engagement.offering} className="bx--row">
              <div className="descriptiveInfo bx--col-sm-12 bx--col-md-12 bx--col-lg-6 bx--col-xl-6">
                <h2>{td('offering_display_name', engagement, i18n)}</h2>
                <p>{td('offering_description', engagement, i18n)}</p>
                <p className="location">{t('location')}</p>
                <p className="garageName">{engagement.location}</p>
              </div>

              <div className="quantInfo bx--col-sm-12 bx--col-md-12 bx--col-lg-6 bx--col-xl-6">
                <div className="quad">
                  <p>{t('status')}</p>
                  <h2>{engagement ? capitalize(engagement.status) : ''}</h2>
                </div>
                <div className="quad">
                  <p>{t('quantity')}</p>
                  <h2>{engagement.requested_quantity}</h2>
                </div>
                <div className="quad">
                  <p>{t('total')}</p>
                  <h2 className="engagementPrice">
                    {engagement.price_formatted
                      ? engagement.price_formatted.replace(/\.[0-9]{2}/, '')
                      : ''}
                  </h2>
                  <p className="taxes">{t('taxes')}</p>
                </div>
                <div className="quad">
                  <p>{t('billed')}</p>
                  <h2>{engagement.billed_quantity}</h2>
                </div>
              </div>
            </div>
          ))
        : ''}

      <div id="lookForwardBig" className="bx--row">
        <img className="banner" src={banner} alt={t('image-alt')} />
        <div className="bannerText">
          <h2>{t('next-heading')}</h2>
          <h3>{t('next-sub-heading')}</h3>
        </div>
      </div>

      <div id="lookForwardSmall" className="bx--row">
        <h2>{t('next-heading')}</h2>
        <h3>{t('next-sub-heading')}</h3>
      </div>

      <div id="learnGarageRow" className="bx--row">
        <h3 className="learnGarage">{t('learn-heading')}</h3>
      </div>

      <div className="bx--row">
        <div className="learnCard bx--col-sm-12 bx--col-md-12 bx--col-lg-4 bx--col-xl-4">
          <div className="logoWrapper blue">
            <img
              className="learnImg"
              src="/images/networking.png"
              alt="Methodology abstract"
            ></img>
          </div>
          <div className="moreInfo">
            <p className="moreInfoTitle">{t('methodology-heading')}</p>
            <p>{t('methodology-text')}</p>
          </div>
          <div className="arrowWrapper">
            <div className="emptySpacer"></div>
            <a
              href="https://www.ibm.com/cloud/expert-labs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="arrow"
                src="/images/arrow.png"
                alt="Arrow button"
              />
            </a>
          </div>
        </div>

        <div className="learnCard bx--col-sm-12 bx--col-md-12 bx--col-lg-4 bx--col-xl-4">
          <div className="logoWrapper greenBlue">
            <img
              className="learnImg"
              src="/images/transform.png"
              alt="Transform abstract"
            ></img>
          </div>
          <div className="moreInfo">
            <p className="moreInfoTitle">{t('architecture-heading')}</p>
            <p>{t('architecture-text')}</p>
          </div>
          <div className="arrowWrapper">
            <div className="emptySpacer"></div>
            <a
              href="https://www.ibm.com/cloud/architecture"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="arrow"
                src="/images/arrow.png"
                alt="Arrow button"
              />
            </a>
          </div>
        </div>

        <div className="learnCard bx--col-sm-12 bx--col-md-12 bx--col-lg-4 bx--col-xl-4">
          <div className="logoWrapper green">
            <img
              className="learnImg"
              src="/images/spaceship.png"
              alt="Spaceship"
            ></img>
          </div>
          <div className="moreInfo">
            <p className="moreInfoTitle">{t('modernize-heading')}</p>
            <p>{t('modernize-text')}</p>
          </div>
          <div className="arrowWrapper">
            <div className="emptySpacer"></div>
            <a
              href="https://www.ibm.com/cloud/application-modernization"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="arrow"
                src="/images/arrow.png"
                alt="Arrow button"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
