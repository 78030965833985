import React, { useEffect } from 'react';
import axios from 'axios';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';
import './App.css';
import EngagementDetails from './Components/EngagementDetails';

const App = () => {
  const [engagementList, setEngagementList] = React.useState('');

  const urlParams = new URLSearchParams(window.location.search);

  const getEngagementFromDatabase = async (instanceId, orderId) => {
    let res;
    try {
      const queryURL = orderId
        ? `/engagement/${instanceId}?orderId=${orderId}`
        : `/engagement/${instanceId}`;
      res = await axios.get(queryURL);
    } catch (e) {
      console.error(e);
      return e;
    }

    return res.data.engagementList;
  };

  const retrieveEngagementInstance = async () => {
    const databaseKey = urlParams.get('instanceId');
    const orderId = urlParams.get('orderId');
    console.log(
      `Retrievng details of instance ${databaseKey}, order ${orderId}`,
    );

    if (databaseKey) {
      const response = await getEngagementFromDatabase(databaseKey, orderId);
      setEngagementList(response);
    }
  };

  useEffect(() => {
    retrieveEngagementInstance();
  }, []);

  return (
    <div className="App" data-testid="success-page">
      <I18nextProvider i18n={i18n}>
        <EngagementDetails
          engagementList={engagementList ? engagementList : []}
        />
      </I18nextProvider>
    </div>
  );
};

export default App;
