/* eslint-disable global-require */
import i18nBrowser from '@console/console-i18n/dist/i18nBrowser';
import { initReactI18next } from 'react-i18next';

const i18nOptions = {
  ns: ['translation'],

  defaultNS: 'translation',
  fallbackLng: 'en',
  initImmediate: false,
  interpolation: {
    escapeValue: false, // not needed for React
  },
  lowerCaseLng: true,
  react: {
    useSuspense: false,
  },

  resources: {
    en: { translation: require('../locales/en/translation') },
  },
};

i18nBrowser.use(initReactI18next).init(i18nOptions);

export default i18nBrowser;

// Provides a translation by looking it up by property name in provided translated data.
// The data should include an i18n property, which is an object keyed by languages.
// If the language is not found, it falls back to English, or just looks on the data itself.
export function td(key, data, { language = 'en' } = {}) {
  const t = (data.i18n && (data.i18n[language] || data.i18n.en)) || data;
  return t[key];
}
